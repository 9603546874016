import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6223af3d = () => interopDefault(import('../pages/candidate.vue' /* webpackChunkName: "pages/candidate" */))
const _4f3c2eeb = () => interopDefault(import('../pages/candidate/_id/index.vue' /* webpackChunkName: "pages/candidate/_id/index" */))
const _0636c3f4 = () => interopDefault(import('../pages/candidate/_id/activity.vue' /* webpackChunkName: "pages/candidate/_id/activity" */))
const _13354b6e = () => interopDefault(import('../pages/candidate/_id/autoSearch.vue' /* webpackChunkName: "pages/candidate/_id/autoSearch" */))
const _d7a2107e = () => interopDefault(import('../pages/candidate/_id/general.vue' /* webpackChunkName: "pages/candidate/_id/general" */))
const _287fbf9a = () => interopDefault(import('../pages/candidate/_id/interview.vue' /* webpackChunkName: "pages/candidate/_id/interview" */))
const _22bdaceb = () => interopDefault(import('../pages/candidate/_id/MatchesTalks.vue' /* webpackChunkName: "pages/candidate/_id/MatchesTalks" */))
const _334537e2 = () => interopDefault(import('../pages/candidate/_id/referrals.vue' /* webpackChunkName: "pages/candidate/_id/referrals" */))
const _0945ec92 = () => interopDefault(import('../pages/company.vue' /* webpackChunkName: "pages/company" */))
const _7100c510 = () => interopDefault(import('../pages/company/_id/contacts.vue' /* webpackChunkName: "pages/company/_id/contacts" */))
const _4abb298a = () => interopDefault(import('../pages/company/_id/general.vue' /* webpackChunkName: "pages/company/_id/general" */))
const _4ae1bbda = () => interopDefault(import('../pages/company/_id/jobs.vue' /* webpackChunkName: "pages/company/_id/jobs" */))
const _7b923bd6 = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _ceb0ac12 = () => interopDefault(import('../pages/job.vue' /* webpackChunkName: "pages/job" */))
const _060d7518 = () => interopDefault(import('../pages/job/_id/autoSearch.vue' /* webpackChunkName: "pages/job/_id/autoSearch" */))
const _5dbeab7b = () => interopDefault(import('../pages/job/_id/general.vue' /* webpackChunkName: "pages/job/_id/general" */))
const _e720f5a0 = () => interopDefault(import('../pages/job/_id/missionSheet.vue' /* webpackChunkName: "pages/job/_id/missionSheet" */))
const _5208f397 = () => interopDefault(import('../pages/job/_id/permissions.vue' /* webpackChunkName: "pages/job/_id/permissions" */))
const _534b3c93 = () => interopDefault(import('../pages/job/_id/pitch.vue' /* webpackChunkName: "pages/job/_id/pitch" */))
const _4292ae09 = () => interopDefault(import('../pages/job/_id/ratings.vue' /* webpackChunkName: "pages/job/_id/ratings" */))
const _baeb71ba = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _fca16384 = () => interopDefault(import('../pages/ai_tools/aiTools.vue' /* webpackChunkName: "pages/ai_tools/aiTools" */))
const _32e10800 = () => interopDefault(import('../pages/ai_tools/kiaditca.js' /* webpackChunkName: "pages/ai_tools/kiaditca" */))
const _060b9bc9 = () => interopDefault(import('../pages/listing/candidate-admitted.vue' /* webpackChunkName: "pages/listing/candidate-admitted" */))
const _1bd61764 = () => interopDefault(import('../pages/listing/candidate-applications.vue' /* webpackChunkName: "pages/listing/candidate-applications" */))
const _e61eac4c = () => interopDefault(import('../pages/listing/candidate-applications/interested.vue' /* webpackChunkName: "pages/listing/candidate-applications/interested" */))
const _3d878042 = () => interopDefault(import('../pages/listing/candidate-applications/new.vue' /* webpackChunkName: "pages/listing/candidate-applications/new" */))
const _9586eca4 = () => interopDefault(import('../pages/listing/candidate-applications/opport-check.vue' /* webpackChunkName: "pages/listing/candidate-applications/opport-check" */))
const _106314e2 = () => interopDefault(import('../pages/listing/candidate-applications/refused.vue' /* webpackChunkName: "pages/listing/candidate-applications/refused" */))
const _0a7dbc2e = () => interopDefault(import('../pages/listing/candidate-db.vue' /* webpackChunkName: "pages/listing/candidate-db" */))
const _0856ea4a = () => interopDefault(import('../pages/listing/candidate-referrals.vue' /* webpackChunkName: "pages/listing/candidate-referrals" */))
const _6ac407ce = () => interopDefault(import('../pages/listing/candidate-referrals/no-referrals.vue' /* webpackChunkName: "pages/listing/candidate-referrals/no-referrals" */))
const _112c4afa = () => interopDefault(import('../pages/listing/candidate-referrals/ref-created.vue' /* webpackChunkName: "pages/listing/candidate-referrals/ref-created" */))
const _70bb6629 = () => interopDefault(import('../pages/listing/candidate-referrals/ref-pending.vue' /* webpackChunkName: "pages/listing/candidate-referrals/ref-pending" */))
const _725d8c00 = () => interopDefault(import('../pages/listing/candidate-referrals/ref-requested.vue' /* webpackChunkName: "pages/listing/candidate-referrals/ref-requested" */))
const _5d03b493 = () => interopDefault(import('../pages/listing/candidate-search.vue' /* webpackChunkName: "pages/listing/candidate-search" */))
const _e6391992 = () => interopDefault(import('../pages/listing/jobs.vue' /* webpackChunkName: "pages/listing/jobs" */))
const _ee118b3c = () => interopDefault(import('../pages/listing/matches.vue' /* webpackChunkName: "pages/listing/matches" */))
const _0c6e9311 = () => interopDefault(import('../pages/listing/matches/candidate-check.vue' /* webpackChunkName: "pages/listing/matches/candidate-check" */))
const _0fb4645d = () => interopDefault(import('../pages/listing/matches/check-before-admission.vue' /* webpackChunkName: "pages/listing/matches/check-before-admission" */))
const _de534a7a = () => interopDefault(import('../pages/listing/matches/client-check.vue' /* webpackChunkName: "pages/listing/matches/client-check" */))
const _78e60783 = () => interopDefault(import('../pages/listing/matches/hired.vue' /* webpackChunkName: "pages/listing/matches/hired" */))
const _126f4112 = () => interopDefault(import('../pages/listing/matches/interested-before-admission.vue' /* webpackChunkName: "pages/listing/matches/interested-before-admission" */))
const _0d7391f5 = () => interopDefault(import('../pages/listing/matches/internal-check.vue' /* webpackChunkName: "pages/listing/matches/internal-check" */))
const _5bea302e = () => interopDefault(import('../pages/listing/matches/interview-steps.vue' /* webpackChunkName: "pages/listing/matches/interview-steps" */))
const _55c7e822 = () => interopDefault(import('../pages/listing/matches/offer.vue' /* webpackChunkName: "pages/listing/matches/offer" */))
const _4f3930ba = () => interopDefault(import('../pages/listing/matches/refused.vue' /* webpackChunkName: "pages/listing/matches/refused" */))
const _4e3ab7e8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/candidate",
    component: _6223af3d,
    name: "candidate",
    children: [{
      path: ":id",
      component: _4f3c2eeb,
      name: "candidate-id"
    }, {
      path: ":id?/activity",
      component: _0636c3f4,
      name: "candidate-id-activity"
    }, {
      path: ":id?/autoSearch",
      component: _13354b6e,
      name: "candidate-id-autoSearch"
    }, {
      path: ":id?/general",
      component: _d7a2107e,
      name: "candidate-id-general"
    }, {
      path: ":id?/interview",
      component: _287fbf9a,
      name: "candidate-id-interview"
    }, {
      path: ":id?/MatchesTalks",
      component: _22bdaceb,
      name: "candidate-id-MatchesTalks"
    }, {
      path: ":id?/referrals",
      component: _334537e2,
      name: "candidate-id-referrals"
    }]
  }, {
    path: "/company",
    component: _0945ec92,
    name: "company",
    children: [{
      path: ":id?/contacts",
      component: _7100c510,
      name: "company-id-contacts"
    }, {
      path: ":id?/general",
      component: _4abb298a,
      name: "company-id-general"
    }, {
      path: ":id?/jobs",
      component: _4ae1bbda,
      name: "company-id-jobs"
    }]
  }, {
    path: "/home",
    component: _7b923bd6,
    name: "home"
  }, {
    path: "/job",
    component: _ceb0ac12,
    name: "job",
    children: [{
      path: ":id?/autoSearch",
      component: _060d7518,
      name: "job-id-autoSearch"
    }, {
      path: ":id?/general",
      component: _5dbeab7b,
      name: "job-id-general"
    }, {
      path: ":id?/missionSheet",
      component: _e720f5a0,
      name: "job-id-missionSheet"
    }, {
      path: ":id?/permissions",
      component: _5208f397,
      name: "job-id-permissions"
    }, {
      path: ":id?/pitch",
      component: _534b3c93,
      name: "job-id-pitch"
    }, {
      path: ":id?/ratings",
      component: _4292ae09,
      name: "job-id-ratings"
    }]
  }, {
    path: "/login",
    component: _baeb71ba,
    name: "login"
  }, {
    path: "/ai_tools/aiTools",
    component: _fca16384,
    name: "ai_tools-aiTools"
  }, {
    path: "/ai_tools/kiaditca",
    component: _32e10800,
    name: "ai_tools-kiaditca"
  }, {
    path: "/listing/candidate-admitted",
    component: _060b9bc9,
    name: "listing-candidate-admitted"
  }, {
    path: "/listing/candidate-applications",
    component: _1bd61764,
    name: "listing-candidate-applications",
    children: [{
      path: "interested",
      component: _e61eac4c,
      name: "listing-candidate-applications-interested"
    }, {
      path: "new",
      component: _3d878042,
      name: "listing-candidate-applications-new"
    }, {
      path: "opport-check",
      component: _9586eca4,
      name: "listing-candidate-applications-opport-check"
    }, {
      path: "refused",
      component: _106314e2,
      name: "listing-candidate-applications-refused"
    }]
  }, {
    path: "/listing/candidate-db",
    component: _0a7dbc2e,
    name: "listing-candidate-db"
  }, {
    path: "/listing/candidate-referrals",
    component: _0856ea4a,
    name: "listing-candidate-referrals",
    children: [{
      path: "no-referrals",
      component: _6ac407ce,
      name: "listing-candidate-referrals-no-referrals"
    }, {
      path: "ref-created",
      component: _112c4afa,
      name: "listing-candidate-referrals-ref-created"
    }, {
      path: "ref-pending",
      component: _70bb6629,
      name: "listing-candidate-referrals-ref-pending"
    }, {
      path: "ref-requested",
      component: _725d8c00,
      name: "listing-candidate-referrals-ref-requested"
    }]
  }, {
    path: "/listing/candidate-search",
    component: _5d03b493,
    name: "listing-candidate-search"
  }, {
    path: "/listing/jobs",
    component: _e6391992,
    name: "listing-jobs"
  }, {
    path: "/listing/matches",
    component: _ee118b3c,
    name: "listing-matches",
    children: [{
      path: "candidate-check",
      component: _0c6e9311,
      name: "listing-matches-candidate-check"
    }, {
      path: "check-before-admission",
      component: _0fb4645d,
      name: "listing-matches-check-before-admission"
    }, {
      path: "client-check",
      component: _de534a7a,
      name: "listing-matches-client-check"
    }, {
      path: "hired",
      component: _78e60783,
      name: "listing-matches-hired"
    }, {
      path: "interested-before-admission",
      component: _126f4112,
      name: "listing-matches-interested-before-admission"
    }, {
      path: "internal-check",
      component: _0d7391f5,
      name: "listing-matches-internal-check"
    }, {
      path: "interview-steps",
      component: _5bea302e,
      name: "listing-matches-interview-steps"
    }, {
      path: "offer",
      component: _55c7e822,
      name: "listing-matches-offer"
    }, {
      path: "refused",
      component: _4f3930ba,
      name: "listing-matches-refused"
    }]
  }, {
    path: "/",
    component: _4e3ab7e8,
    name: "index"
  }, {
    path: "/company/:id/",
    redirect: "/company/:id/general",
    name: "companyId"
  }, {
    path: "/job/:id/",
    redirect: "/job/:id/general",
    name: "jobId"
  }, {
    path: "/listing/matches/",
    redirect: "/listing/matches/internal-check",
    name: "listingMatches"
  }, {
    path: "/listing/application/",
    redirect: "/listing/candidate-applications/new",
    name: "candidateListing"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
