/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import snakeCase from 'lodash.snakecase'
import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { FacetsResults, SearchFilter, Search } from '~/models/Search'
import SearchService from '~/services/search-service'

@Module({
    name: 'search',
    stateFactory: true,
    namespaced: true,
})
export default class SearchModule extends VuexModule {
    filters: Record<string, SearchFilter> = {}
    aggregations: Record<string, Record<number, number>> = {}
    facets: FacetsResults = {} as FacetsResults
    searchTerms = ''
    sortField = '_score'
    sortOrder = 'desc'
    results: Array<Record<string, any>> = []
    currentPage = 1
    pageCount: number
    paginationSize = 50
    areFacetsLoading = true
    areResultsLoading = true
    areSearchesLoading = true
    resetPagination = false
    totalHits = 1
    searches: Array<Search> = []
    activeSearch = 0

    get GetPageCount() {
        return (): number => {
            return Math.ceil(this.totalHits / this.paginationSize)
        }
    }

    get GetFilters() {
        return (name: string): SearchFilter => {
            return this.filters[name]
        }
    }

    get IsLoading() {
        return (): boolean => {
            if (!this.areFacetsLoading && !this.areResultsLoading) {
                return false
            }
            return true
        }
    }

    get GetActiveFiltersCount() {
        return (name: string): number => {
            const filters = this.GetFilters(name)
            if (!filters) return 0

            const valueIsActive =
                typeof filters.value === 'boolean'
                    ? filters.value
                    : Array.isArray(filters.value) && filters.value.length > 0
            const conditionsIsActive = Array.isArray(filters.conditions) && filters.conditions.length > 0

            return valueIsActive || conditionsIsActive ? 1 : 0
        }
    }

    get GetFacets() {
        return (name: string): Record<number, string> => {
            if (this.facets) {
                return this.facets[name]
            }
            return {}
        }
    }

    get GetSearches() {
        return (): Array<Search> => {
            if (this.searches) {
                return this.searches
            }
            return []
        }
    }

    get GetAggregations() {
        return (name: string): Record<number, number> => {
            return this.aggregations[name]
        }
    }

    get GetSearchTerms() {
        return (): string => {
            return this.searchTerms
        }
    }

    get GetAllActiveFiltersCount() {
        return (): number => {
            let total = 0
            for (const name in this.filters) {
                const count = this.GetActiveFiltersCount(name)
                total += count
            }
            return total
        }
    }

    @Mutation
    setActiveSearch({ id, terms, filters }: { id: number; terms: string; filters: Record<string, SearchFilter> }) {
        this.activeSearch = id
        this.searchTerms = terms
        this.filters = filters
    }

    @Mutation
    setFilters({ name, filter }: { name: string; filter: SearchFilter }) {
        Vue.set(this.filters, name, filter)
        this.resetPagination = true
    }

    @Mutation
    setSortField(field: string) {
        this.sortField = field
    }

    @Mutation
    setSortOrder(order: string) {
        this.sortOrder = order
    }

    @Mutation
    setLastStatusUpdateDate(startDate: string) {
        Vue.set(this.filters, 'lastStatusUpdateDate', { startDate, endDate: new Date().toISOString().slice(0, 10) })
        this.resetPagination = true
    }

    @Mutation
    setAggregation({ name, values }: { name: string; values: Record<number, number> }) {
        Vue.set(this.aggregations, name, values)
    }

    @Mutation
    setFacets(values: FacetsResults) {
        this.facets = values
    }

    @Mutation
    setSearchTerms(terms: string) {
        this.searchTerms = terms
        this.resetPagination = true
    }

    @Mutation
    setSearches(searches: Array<Search>) {
        this.searches = searches
    }

    @Mutation
    setResults(results: Array<Record<string, any>>) {
        this.results = results
    }

    @Mutation
    resetFilters() {
        this.filters = {}
        this.resetPagination = true
    }

    @Mutation
    setCurrentPage(page: number) {
        this.currentPage = page
    }

    @Mutation
    setFacetsLoading(value: boolean) {
        this.areFacetsLoading = value
    }

    @Mutation
    setResultsLoading(value: boolean) {
        this.areResultsLoading = value
    }

    @Mutation
    setSearchesLoading(value: boolean) {
        this.areSearchesLoading = value
    }

    @Mutation
    setTotalHits(value: number) {
        this.totalHits = value
    }

    @Mutation
    cleanPagination() {
        if (this.resetPagination) {
            this.currentPage = 1
            this.resetPagination = false
        }
    }

    @Action
    async DO_SEARCH(callback?: () => void): Promise<void> {
        this.context.commit('setResultsLoading', true)
        window.scrollTo(0, 0)
        this.context.commit('cleanPagination')
        const filters: Record<string, any> = {}
        for (const name in this.filters) {
            const searchFilter = this.GetFilters(name)
            if (searchFilter.value || searchFilter.conditions) {
                filters[snakeCase(name)] = searchFilter
            }
        }

        const searchResult = await SearchService.doSearch({
            terms: this.searchTerms || '*',
            filters,
            page: this.currentPage,
            size: this.paginationSize,
            sortField: this.sortField,
            sortOrder: this.sortOrder,
        })

        for (const agg in searchResult.aggregations) {
            const values: Record<number, number> = {}
            searchResult.aggregations[agg].forEach((doc: any) => {
                values[doc.key] = doc.docCount
            })
            this.setAggregation({
                name: agg,
                values,
            })
        }
        this.setTotalHits(searchResult.total)
        this.setResults(searchResult.results)
        this.setResultsLoading(false)
    }

    @Action
    async GET_FACETS(): Promise<void> {
        this.setFacetsLoading(true)
        const facetsResults = await SearchService.getFacets()
        this.setFacets(facetsResults)
        this.setFacetsLoading(false)
    }

    @Action
    async GET_SEARCHES(): Promise<void> {
        this.setSearchesLoading(true)
        const searches = await SearchService.getSearches()
        this.setSearches(searches)
        this.setSearchesLoading(false)
    }

    @Action({ rawError: true })
    async SAVE_SEARCH(name: string): Promise<void> {
        try {
            const response = await SearchService.saveSearch(name, this.searchTerms, this.filters)
            this.setActiveSearch({ id: response.id, terms: response.terms, filters: response.filters })
            this.GET_SEARCHES()
        } catch (error: any) {
            throw error.response
        }
    }

    @Action
    async DELETE_SEARCH(searchId: number): Promise<void> {
        try {
            await SearchService.deleteSearch(searchId)
            this.setActiveSearch({ id: 0, terms: this.searchTerms, filters: this.filters })
            this.GET_SEARCHES()
        } catch (error: any) {
            throw error.response
        }
    }
}
