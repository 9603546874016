export const Avatar = () => import('../../components/Avatar.vue' /* webpackChunkName: "components/avatar" */).then(c => wrapFunctional(c.default || c))
export const CompanySettings = () => import('../../components/CompanySettings.vue' /* webpackChunkName: "components/company-settings" */).then(c => wrapFunctional(c.default || c))
export const DatetimePicker = () => import('../../components/DatetimePicker.vue' /* webpackChunkName: "components/datetime-picker" */).then(c => wrapFunctional(c.default || c))
export const JobSettings = () => import('../../components/JobSettings.vue' /* webpackChunkName: "components/job-settings" */).then(c => wrapFunctional(c.default || c))
export const MainViewSwitch = () => import('../../components/MainViewSwitch.vue' /* webpackChunkName: "components/main-view-switch" */).then(c => wrapFunctional(c.default || c))
export const MandatoryChip = () => import('../../components/MandatoryChip.vue' /* webpackChunkName: "components/mandatory-chip" */).then(c => wrapFunctional(c.default || c))
export const MandatoryTooltip = () => import('../../components/MandatoryTooltip.vue' /* webpackChunkName: "components/mandatory-tooltip" */).then(c => wrapFunctional(c.default || c))
export const PdfViewer = () => import('../../components/PdfViewer.vue' /* webpackChunkName: "components/pdf-viewer" */).then(c => wrapFunctional(c.default || c))
export const SkeletonLoader = () => import('../../components/SkeletonLoader.vue' /* webpackChunkName: "components/skeleton-loader" */).then(c => wrapFunctional(c.default || c))
export const Tiptap = () => import('../../components/Tiptap.vue' /* webpackChunkName: "components/tiptap" */).then(c => wrapFunctional(c.default || c))
export const Toaster = () => import('../../components/Toaster.vue' /* webpackChunkName: "components/toaster" */).then(c => wrapFunctional(c.default || c))
export const UserSettings = () => import('../../components/UserSettings.vue' /* webpackChunkName: "components/user-settings" */).then(c => wrapFunctional(c.default || c))
export const ActionButtons = () => import('../../components/action-buttons/ActionButtons.vue' /* webpackChunkName: "components/action-buttons" */).then(c => wrapFunctional(c.default || c))
export const ActionButtonsSequences = () => import('../../components/action-buttons/Sequences.vue' /* webpackChunkName: "components/action-buttons-sequences" */).then(c => wrapFunctional(c.default || c))
export const AutoSearchFitAverage = () => import('../../components/auto-search/FitAverage.vue' /* webpackChunkName: "components/auto-search-fit-average" */).then(c => wrapFunctional(c.default || c))
export const AutoSearchCandidate = () => import('../../components/auto-search/SearchCandidate.vue' /* webpackChunkName: "components/auto-search-candidate" */).then(c => wrapFunctional(c.default || c))
export const Availability = () => import('../../components/availability/Availability.vue' /* webpackChunkName: "components/availability" */).then(c => wrapFunctional(c.default || c))
export const BarsActionBar = () => import('../../components/bars/ActionBar.vue' /* webpackChunkName: "components/bars-action-bar" */).then(c => wrapFunctional(c.default || c))
export const BarsActionBarJob = () => import('../../components/bars/ActionBarJob.vue' /* webpackChunkName: "components/bars-action-bar-job" */).then(c => wrapFunctional(c.default || c))
export const BarsAppBar = () => import('../../components/bars/AppBar.vue' /* webpackChunkName: "components/bars-app-bar" */).then(c => wrapFunctional(c.default || c))
export const BarsAppBarDesktop = () => import('../../components/bars/AppBarDesktop.vue' /* webpackChunkName: "components/bars-app-bar-desktop" */).then(c => wrapFunctional(c.default || c))
export const BarsAppBarMobile = () => import('../../components/bars/AppBarMobile.vue' /* webpackChunkName: "components/bars-app-bar-mobile" */).then(c => wrapFunctional(c.default || c))
export const ActivityCancelSendingDialog = () => import('../../components/activity/CancelSendingDialog.vue' /* webpackChunkName: "components/activity-cancel-sending-dialog" */).then(c => wrapFunctional(c.default || c))
export const ActivityConsultNotificationDialog = () => import('../../components/activity/ConsultNotificationDialog.vue' /* webpackChunkName: "components/activity-consult-notification-dialog" */).then(c => wrapFunctional(c.default || c))
export const BusinessModelsMastered = () => import('../../components/business-models/BusinessModelsMastered.vue' /* webpackChunkName: "components/business-models-mastered" */).then(c => wrapFunctional(c.default || c))
export const BusinessModelsPreference = () => import('../../components/business-models/BusinessModelsPreference.vue' /* webpackChunkName: "components/business-models-preference" */).then(c => wrapFunctional(c.default || c))
export const ButtonsTooltipButton = () => import('../../components/buttons/TooltipButton.vue' /* webpackChunkName: "components/buttons-tooltip-button" */).then(c => wrapFunctional(c.default || c))
export const CitiesWanted = () => import('../../components/cities-wanted/CitiesWanted.vue' /* webpackChunkName: "components/cities-wanted" */).then(c => wrapFunctional(c.default || c))
export const CitiesWantedSelectCity = () => import('../../components/cities-wanted/SelectCity.vue' /* webpackChunkName: "components/cities-wanted-select-city" */).then(c => wrapFunctional(c.default || c))
export const ContactCard = () => import('../../components/contact-card/ContactCard.vue' /* webpackChunkName: "components/contact-card" */).then(c => wrapFunctional(c.default || c))
export const ContactCardSendAccessContactDialog = () => import('../../components/contact-card/SendAccessContactDialog.vue' /* webpackChunkName: "components/contact-card-send-access-contact-dialog" */).then(c => wrapFunctional(c.default || c))
export const ContactCardSettingsContactDialog = () => import('../../components/contact-card/SettingsContactDialog.vue' /* webpackChunkName: "components/contact-card-settings-contact-dialog" */).then(c => wrapFunctional(c.default || c))
export const DialogsContactDialog = () => import('../../components/dialogs/ContactDialog.vue' /* webpackChunkName: "components/dialogs-contact-dialog" */).then(c => wrapFunctional(c.default || c))
export const DialogsGeneralMessage = () => import('../../components/dialogs/GeneralMessage.vue' /* webpackChunkName: "components/dialogs-general-message" */).then(c => wrapFunctional(c.default || c))
export const DialogsGeneralMessageJob = () => import('../../components/dialogs/GeneralMessageJob.vue' /* webpackChunkName: "components/dialogs-general-message-job" */).then(c => wrapFunctional(c.default || c))
export const DialogsRefuseSuggestionDialog = () => import('../../components/dialogs/RefuseSuggestionDialog.vue' /* webpackChunkName: "components/dialogs-refuse-suggestion-dialog" */).then(c => wrapFunctional(c.default || c))
export const DialogsValidatePitchDialog = () => import('../../components/dialogs/ValidatePitchDialog.vue' /* webpackChunkName: "components/dialogs-validate-pitch-dialog" */).then(c => wrapFunctional(c.default || c))
export const DisplaysDisplayAvailability = () => import('../../components/displays/DisplayAvailability.vue' /* webpackChunkName: "components/displays-display-availability" */).then(c => wrapFunctional(c.default || c))
export const DisplaysDisplayGeneralMessage = () => import('../../components/displays/DisplayGeneralMessage.vue' /* webpackChunkName: "components/displays-display-general-message" */).then(c => wrapFunctional(c.default || c))
export const DisplaysDisplayKeywordsSearch = () => import('../../components/displays/DisplayKeywordsSearch.vue' /* webpackChunkName: "components/displays-display-keywords-search" */).then(c => wrapFunctional(c.default || c))
export const DisplaysDisplayOldNotes = () => import('../../components/displays/DisplayOldNotes.vue' /* webpackChunkName: "components/displays-display-old-notes" */).then(c => wrapFunctional(c.default || c))
export const DisplaysDisplayOldStrengths = () => import('../../components/displays/DisplayOldStrengths.vue' /* webpackChunkName: "components/displays-display-old-strengths" */).then(c => wrapFunctional(c.default || c))
export const DisplaysManagementExperience = () => import('../../components/displays/ManagementExperience.vue' /* webpackChunkName: "components/displays-management-experience" */).then(c => wrapFunctional(c.default || c))
export const ExperiencesDisplayExperience = () => import('../../components/experiences/DisplayExperience.vue' /* webpackChunkName: "components/experiences-display-experience" */).then(c => wrapFunctional(c.default || c))
export const ExperiencesEditExperience = () => import('../../components/experiences/EditExperience.vue' /* webpackChunkName: "components/experiences-edit-experience" */).then(c => wrapFunctional(c.default || c))
export const ExperiencesExperienceCard = () => import('../../components/experiences/ExperienceCard.vue' /* webpackChunkName: "components/experiences-experience-card" */).then(c => wrapFunctional(c.default || c))
export const Experiences = () => import('../../components/experiences/Experiences.vue' /* webpackChunkName: "components/experiences" */).then(c => wrapFunctional(c.default || c))
export const FiltersCollapsibleFilterAutocomplete = () => import('../../components/filters/CollapsibleFilterAutocomplete.vue' /* webpackChunkName: "components/filters-collapsible-filter-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const FiltersDynamicMultiSelectAutocomplete = () => import('../../components/filters/DynamicMultiSelectAutocomplete.vue' /* webpackChunkName: "components/filters-dynamic-multi-select-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const HeadersCandidateHeader = () => import('../../components/headers/CandidateHeader.vue' /* webpackChunkName: "components/headers-candidate-header" */).then(c => wrapFunctional(c.default || c))
export const HeadersSettings = () => import('../../components/headers/Settings.vue' /* webpackChunkName: "components/headers-settings" */).then(c => wrapFunctional(c.default || c))
export const IconsIconHubspot = () => import('../../components/icons/IconHubspot.vue' /* webpackChunkName: "components/icons-icon-hubspot" */).then(c => wrapFunctional(c.default || c))
export const IconsIconSalary = () => import('../../components/icons/IconSalary.vue' /* webpackChunkName: "components/icons-icon-salary" */).then(c => wrapFunctional(c.default || c))
export const IndustriesMastered = () => import('../../components/industries/IndustriesMastered.vue' /* webpackChunkName: "components/industries-mastered" */).then(c => wrapFunctional(c.default || c))
export const IndustriesPreference = () => import('../../components/industries/IndustriesPreference.vue' /* webpackChunkName: "components/industries-preference" */).then(c => wrapFunctional(c.default || c))
export const InputsInputChipsCheckbox = () => import('../../components/inputs/InputChipsCheckbox.vue' /* webpackChunkName: "components/inputs-input-chips-checkbox" */).then(c => wrapFunctional(c.default || c))
export const InputsInputHideFromCompanies = () => import('../../components/inputs/InputHideFromCompanies.vue' /* webpackChunkName: "components/inputs-input-hide-from-companies" */).then(c => wrapFunctional(c.default || c))
export const InputsInputPhoneNumber = () => import('../../components/inputs/InputPhoneNumber.vue' /* webpackChunkName: "components/inputs-input-phone-number" */).then(c => wrapFunctional(c.default || c))
export const InputsSalaries = () => import('../../components/inputs/Salaries.vue' /* webpackChunkName: "components/inputs-salaries" */).then(c => wrapFunctional(c.default || c))
export const InputsSalaryGroup = () => import('../../components/inputs/SalaryGroup.vue' /* webpackChunkName: "components/inputs-salary-group" */).then(c => wrapFunctional(c.default || c))
export const JobProfileGeneralTab = () => import('../../components/job-profile/GeneralTab.vue' /* webpackChunkName: "components/job-profile-general-tab" */).then(c => wrapFunctional(c.default || c))
export const JobContactCard = () => import('../../components/job-profile/JobContactCard.vue' /* webpackChunkName: "components/job-contact-card" */).then(c => wrapFunctional(c.default || c))
export const JobHeader = () => import('../../components/job-profile/JobHeader.vue' /* webpackChunkName: "components/job-header" */).then(c => wrapFunctional(c.default || c))
export const JobProfileListingJobProfile = () => import('../../components/job-profile/ListingJobProfile.vue' /* webpackChunkName: "components/job-profile-listing-job-profile" */).then(c => wrapFunctional(c.default || c))
export const JobProfileMissionSheetTab = () => import('../../components/job-profile/MissionSheetTab.vue' /* webpackChunkName: "components/job-profile-mission-sheet-tab" */).then(c => wrapFunctional(c.default || c))
export const JobProfilePermissionsTab = () => import('../../components/job-profile/PermissionsTab.vue' /* webpackChunkName: "components/job-profile-permissions-tab" */).then(c => wrapFunctional(c.default || c))
export const JobProfilePitchTab = () => import('../../components/job-profile/PitchTab.vue' /* webpackChunkName: "components/job-profile-pitch-tab" */).then(c => wrapFunctional(c.default || c))
export const JobProfileRatingsTab = () => import('../../components/job-profile/RatingsTab.vue' /* webpackChunkName: "components/job-profile-ratings-tab" */).then(c => wrapFunctional(c.default || c))
export const JobsWantedSelectJob = () => import('../../components/jobs-wanted/SelectJob.vue' /* webpackChunkName: "components/jobs-wanted-select-job" */).then(c => wrapFunctional(c.default || c))
export const LanguagesMastered = () => import('../../components/languages/LanguagesMastered.vue' /* webpackChunkName: "components/languages-mastered" */).then(c => wrapFunctional(c.default || c))
export const ListingPaginationCounter = () => import('../../components/listing/PaginationCounter.vue' /* webpackChunkName: "components/listing-pagination-counter" */).then(c => wrapFunctional(c.default || c))
export const ListsNavigationList = () => import('../../components/lists/NavigationList.vue' /* webpackChunkName: "components/lists-navigation-list" */).then(c => wrapFunctional(c.default || c))
export const MatchCreationCandidateSearch = () => import('../../components/match-creation/CandidateSearch.vue' /* webpackChunkName: "components/match-creation-candidate-search" */).then(c => wrapFunctional(c.default || c))
export const MatchCreationCandidatesResults = () => import('../../components/match-creation/CandidatesResults.vue' /* webpackChunkName: "components/match-creation-candidates-results" */).then(c => wrapFunctional(c.default || c))
export const MatchCreationCompaniesResults = () => import('../../components/match-creation/CompaniesResults.vue' /* webpackChunkName: "components/match-creation-companies-results" */).then(c => wrapFunctional(c.default || c))
export const MatchCreationCompanySearch = () => import('../../components/match-creation/CompanySearch.vue' /* webpackChunkName: "components/match-creation-company-search" */).then(c => wrapFunctional(c.default || c))
export const MatchCreationFirstPanel = () => import('../../components/match-creation/FirstPanel.vue' /* webpackChunkName: "components/match-creation-first-panel" */).then(c => wrapFunctional(c.default || c))
export const MatchCreationMultiplePanel = () => import('../../components/match-creation/MatchCreationMultiplePanel.vue' /* webpackChunkName: "components/match-creation-multiple-panel" */).then(c => wrapFunctional(c.default || c))
export const MatchCreationPanel = () => import('../../components/match-creation/MatchCreationPanel.vue' /* webpackChunkName: "components/match-creation-panel" */).then(c => wrapFunctional(c.default || c))
export const MatchCreationSecondPanel = () => import('../../components/match-creation/SecondPanel.vue' /* webpackChunkName: "components/match-creation-second-panel" */).then(c => wrapFunctional(c.default || c))
export const MatchCreationThirdPanel = () => import('../../components/match-creation/ThirdPanel.vue' /* webpackChunkName: "components/match-creation-third-panel" */).then(c => wrapFunctional(c.default || c))
export const MatchProfileListingMatchProfile = () => import('../../components/match-profile/ListingMatchProfile.vue' /* webpackChunkName: "components/match-profile-listing-match-profile" */).then(c => wrapFunctional(c.default || c))
export const MatchActivity = () => import('../../components/match-profile/MatchActivity.vue' /* webpackChunkName: "components/match-activity" */).then(c => wrapFunctional(c.default || c))
export const MatchInfos = () => import('../../components/match-profile/MatchInfos.vue' /* webpackChunkName: "components/match-infos" */).then(c => wrapFunctional(c.default || c))
export const MatchesTalksTimelineItem = () => import('../../components/matches-talks/MatchesTalksTimelineItem.vue' /* webpackChunkName: "components/matches-talks-timeline-item" */).then(c => wrapFunctional(c.default || c))
export const MatchesTalksSingleMatchTalk = () => import('../../components/matches-talks/SingleMatchTalk.vue' /* webpackChunkName: "components/matches-talks-single-match-talk" */).then(c => wrapFunctional(c.default || c))
export const MaturitiesWished = () => import('../../components/maturities/MaturitiesWished.vue' /* webpackChunkName: "components/maturities-wished" */).then(c => wrapFunctional(c.default || c))
export const NavigationDrawersLeftNavigationDrawer = () => import('../../components/navigation-drawers/LeftNavigationDrawer.vue' /* webpackChunkName: "components/navigation-drawers-left-navigation-drawer" */).then(c => wrapFunctional(c.default || c))
export const NoticesNoticeCard = () => import('../../components/notices/NoticeCard.vue' /* webpackChunkName: "components/notices-notice-card" */).then(c => wrapFunctional(c.default || c))
export const NoticesPanel = () => import('../../components/notices/NoticesPanel.vue' /* webpackChunkName: "components/notices-panel" */).then(c => wrapFunctional(c.default || c))
export const ReferralsReferralAnswers = () => import('../../components/referrals/ReferralAnswers.vue' /* webpackChunkName: "components/referrals-referral-answers" */).then(c => wrapFunctional(c.default || c))
export const ReferralsReferralCard = () => import('../../components/referrals/ReferralCard.vue' /* webpackChunkName: "components/referrals-referral-card" */).then(c => wrapFunctional(c.default || c))
export const ReferralsReferralDialog = () => import('../../components/referrals/ReferralDialog.vue' /* webpackChunkName: "components/referrals-referral-dialog" */).then(c => wrapFunctional(c.default || c))
export const ReferralsReferralGeneral = () => import('../../components/referrals/ReferralGeneral.vue' /* webpackChunkName: "components/referrals-referral-general" */).then(c => wrapFunctional(c.default || c))
export const ReferralsReferralInformations = () => import('../../components/referrals/ReferralInformations.vue' /* webpackChunkName: "components/referrals-referral-informations" */).then(c => wrapFunctional(c.default || c))
export const Referrals = () => import('../../components/referrals/Referrals.vue' /* webpackChunkName: "components/referrals" */).then(c => wrapFunctional(c.default || c))
export const SearchSavedSearch = () => import('../../components/search/SavedSearch.vue' /* webpackChunkName: "components/search-saved-search" */).then(c => wrapFunctional(c.default || c))
export const SearchSavedSearchList = () => import('../../components/search/SavedSearchList.vue' /* webpackChunkName: "components/search-saved-search-list" */).then(c => wrapFunctional(c.default || c))
export const SearchBar = () => import('../../components/search/SearchBar.vue' /* webpackChunkName: "components/search-bar" */).then(c => wrapFunctional(c.default || c))
export const SearchBooleanFilterPanel = () => import('../../components/search/SearchBooleanFilterPanel.vue' /* webpackChunkName: "components/search-boolean-filter-panel" */).then(c => wrapFunctional(c.default || c))
export const SearchDateRangeFilterPanel = () => import('../../components/search/SearchDateRangeFilterPanel.vue' /* webpackChunkName: "components/search-date-range-filter-panel" */).then(c => wrapFunctional(c.default || c))
export const SearchFilters = () => import('../../components/search/SearchFilters.vue' /* webpackChunkName: "components/search-filters" */).then(c => wrapFunctional(c.default || c))
export const SearchJobsFilterPanel = () => import('../../components/search/SearchJobsFilterPanel.vue' /* webpackChunkName: "components/search-jobs-filter-panel" */).then(c => wrapFunctional(c.default || c))
export const SearchMultiSelectFilterPanel = () => import('../../components/search/SearchMultiSelectFilterPanel.vue' /* webpackChunkName: "components/search-multi-select-filter-panel" */).then(c => wrapFunctional(c.default || c))
export const SearchNumberFilterPanel = () => import('../../components/search/SearchNumberFilterPanel.vue' /* webpackChunkName: "components/search-number-filter-panel" */).then(c => wrapFunctional(c.default || c))
export const SearchResults = () => import('../../components/search/SearchResults.vue' /* webpackChunkName: "components/search-results" */).then(c => wrapFunctional(c.default || c))
export const SearchSorting = () => import('../../components/search/SearchSorting.vue' /* webpackChunkName: "components/search-sorting" */).then(c => wrapFunctional(c.default || c))
export const SearchTipsAccordion = () => import('../../components/search/SearchTipsAccordion.vue' /* webpackChunkName: "components/search-tips-accordion" */).then(c => wrapFunctional(c.default || c))
export const SearchBarCardContent = () => import('../../components/search-bar/CardContent.vue' /* webpackChunkName: "components/search-bar-card-content" */).then(c => wrapFunctional(c.default || c))
export const SearchBarResultCard = () => import('../../components/search-bar/ResultCard.vue' /* webpackChunkName: "components/search-bar-result-card" */).then(c => wrapFunctional(c.default || c))
export const SegmentationsMastered = () => import('../../components/segmentations/SegmentationsMastered.vue' /* webpackChunkName: "components/segmentations-mastered" */).then(c => wrapFunctional(c.default || c))
export const Technologies = () => import('../../components/technologies/Technologies.vue' /* webpackChunkName: "components/technologies" */).then(c => wrapFunctional(c.default || c))
export const AutoSearchHeaderCandidate = () => import('../../components/auto-search/candidate/AutoSearchHeaderCandidate.vue' /* webpackChunkName: "components/auto-search-header-candidate" */).then(c => wrapFunctional(c.default || c))
export const AutoSearchJobProfile = () => import('../../components/auto-search/candidate/AutoSearchJobProfile.vue' /* webpackChunkName: "components/auto-search-job-profile" */).then(c => wrapFunctional(c.default || c))
export const AutoSearchCandidateJobCard = () => import('../../components/auto-search/candidate/JobCard.vue' /* webpackChunkName: "components/auto-search-candidate-job-card" */).then(c => wrapFunctional(c.default || c))
export const AutoSearchCandidateProfile = () => import('../../components/auto-search/job/AutoSearchCandidateProfile.vue' /* webpackChunkName: "components/auto-search-candidate-profile" */).then(c => wrapFunctional(c.default || c))
export const AutoSearchHeaderJob = () => import('../../components/auto-search/job/AutoSearchHeaderJob.vue' /* webpackChunkName: "components/auto-search-header-job" */).then(c => wrapFunctional(c.default || c))
export const AutoSearchJob = () => import('../../components/auto-search/job/AutoSearchJob.vue' /* webpackChunkName: "components/auto-search-job" */).then(c => wrapFunctional(c.default || c))
export const AutoSearchJobCandidateCard = () => import('../../components/auto-search/job/CandidateCard.vue' /* webpackChunkName: "components/auto-search-job-candidate-card" */).then(c => wrapFunctional(c.default || c))
export const ListingMatchesMatchName = () => import('../../components/listing/matches/MatchName.vue' /* webpackChunkName: "components/listing-matches-match-name" */).then(c => wrapFunctional(c.default || c))
export const ListingCandidatesCandidateOpportunity = () => import('../../components/listing/candidates/CandidateOpportunity.vue' /* webpackChunkName: "components/listing-candidates-candidate-opportunity" */).then(c => wrapFunctional(c.default || c))
export const ListingCandidatesCandidateStackingDrawer = () => import('../../components/listing/candidates/CandidateStackingDrawer.vue' /* webpackChunkName: "components/listing-candidates-candidate-stacking-drawer" */).then(c => wrapFunctional(c.default || c))
export const ListingCandidatesCityFilter = () => import('../../components/listing/candidates/CityFilter.vue' /* webpackChunkName: "components/listing-candidates-city-filter" */).then(c => wrapFunctional(c.default || c))
export const ListingCandidatesJobFilter = () => import('../../components/listing/candidates/JobFilter.vue' /* webpackChunkName: "components/listing-candidates-job-filter" */).then(c => wrapFunctional(c.default || c))
export const ListingCandidatesPracticeFilter = () => import('../../components/listing/candidates/PracticeFilter.vue' /* webpackChunkName: "components/listing-candidates-practice-filter" */).then(c => wrapFunctional(c.default || c))
export const ListingCandidatesStatusFilter = () => import('../../components/listing/candidates/StatusFilter.vue' /* webpackChunkName: "components/listing-candidates-status-filter" */).then(c => wrapFunctional(c.default || c))
export const ListingCandidatesTeamUserFilter = () => import('../../components/listing/candidates/TeamUserFilter.vue' /* webpackChunkName: "components/listing-candidates-team-user-filter" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
