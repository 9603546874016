import { PrioritisedJob, PrioritisedCity, NestedOpportunity } from './index'
import { componentsStore } from '~/utils/store-accessor'

export class Candidate {
    public constructor(
        public readonly id: number = 1,
        public firstName: string = '',
        public lastName: string = '',
        public readonly contact: number = 1,
        public readonly user: number = 1,
        public coach: number = 1,
        public coachFirstName: string = '',
        public coachLastName: string = '',
        public pictureUrl: string = '',
        public lastStatusUpdate: string = '',
        public lastApplyInteraction: string = '',
        public dateTeamInterview: string = '',
        public stepName: string = '',
        public processName: string = '',
        public generalMessage: string = '',
        public currentStatus: number = 1,
        public currentStatusString: string = '',
        public practice?: number,
        public actions: any = {},
        public coachPictureUrl: string = '',
        public fullName: string = '',
        public refusedFitsCount: number = 0,
        public availableAsap: boolean = false,
        public dateAvailable?: string,
        public priorNotice?: number,
        public currentPackage?: number,
        public currentPackageFixed?: number,
        public currentPackageVariable?: number,
        public minimumPackage?: number,
        public minimumPackageFixed?: number,
        public minimumPackageVariable?: number,
        public wantedPackage?: number,
        public wantedPackageFixed?: number,
        public wantedPackageVariable?: number,
        public cvUrl: string = '',
        public dreamJob: string = '',
        public noGo: string = '',
        public industries: Array<any> = [],
        public otherHiringProcesses: string = '',
        public salaryDetails: string = '',
        public strengths: string = '',
        public wantedJobExplanation: string = '',
        public weaknesses: string = '',
        public wishes: string = '',
        public citiesWanted: Array<PrioritisedCity> = [],
        public jobsWanted: Array<PrioritisedJob> = [],
        public experience: number = 1,
        public questionnaireLanguagesFluent: Array<number> = [],
        public questionnaireTechnosWanted: Array<number> = [],
        public interviewTechnosMastered: Array<number> = [],
        public interviewSectorWanted: Array<number> = [],
        public interviewSectorNoGo: Array<number> = [],
        public interviewSectorMastered: Array<number> = [],
        public interviewTypeOfBusiness: Array<number> = [],
        public interviewTargetMarket: Array<number> = [],
        public interviewMaturityWanted: Array<number> = [],
        public maturities: Array<any> = [],
        public personalAchievements: string = '',
        public educationTypes: Array<number> = [],
        public interviewNotes: string = '',
        public generalStrengths: string = '',
        public languages: Array<any> = [],
        public technologies: Array<any> = [],
        public segmentations: Array<any> = [],
        public businessModels: Array<any> = [],
        public languagesExplanation: string = '',
        public mbti: string = '',
        public managementExperience: Array<any> = [],
        public processAtIp: string = '',
        public shouldDisplayInterviewDate: boolean = false,
        public dateHunted: string = '',
        public githubLink: string = '',
        public remotePolicies: Array<number> = [],
        public remotePoliciesExplanation: string = '',
        public proposedOpportunities: Array<NestedOpportunity> = [],
        public interestedIn: Array<NestedOpportunity> = [],
        public notInterestedIn: Array<NestedOpportunity> = []
    ) {}

    getFullName(): string {
        return `${this.firstName} ${this.lastName}`
    }

    getCoachFullName(): string {
        return `${this.coachFirstName} ${this.coachLastName}`
    }

    getCoachName(): string {
        return `${this.coachFirstName} ${this.coachLastName[0]}`
    }

    isCandidateTechnical(): boolean {
        return this.practice === 5
    }

    static getInstancesWithJobsName(jobsWanted: Array<any>): Array<PrioritisedJob> {
        return jobsWanted.map((job) => {
            return PrioritisedJob.toPrioritisedJob(job)
        })
    }

    static getInstancesWithCitiesName(citiesWanted: Array<any>): Array<PrioritisedCity> {
        return citiesWanted.map((city) => {
            return PrioritisedCity.toPrioritisedCity(city)
        })
    }

    static getLanguagesWithName(languages: Array<number>): Array<string> {
        return languages.map((language) => {
            return componentsStore.componentNameById(language, 'languages')
        })
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static fromObject(candidate: any): Candidate {
        if (candidate.jobsWanted) {
            candidate.jobsWanted = Candidate.getInstancesWithJobsName(candidate.jobsWanted)
        }
        if (candidate.citiesWanted) {
            candidate.citiesWanted = Candidate.getInstancesWithCitiesName(candidate.citiesWanted)
        }
        return Object.assign(new Candidate(candidate.id), candidate)
    }
}
