//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SearchMultiSelectFilterPanel from './SearchMultiSelectFilterPanel.vue'
import SearchDateRangeFilterPanel from './SearchDateRangeFilterPanel.vue'
import SearchBooleanFilterPanel from './SearchBooleanFilterPanel.vue'
import SearchJobsFilterPanel from './SearchJobsFilterPanel'

export default {
    components: {
        SearchMultiSelectFilterPanel,
        SearchDateRangeFilterPanel,
        SearchBooleanFilterPanel,
        SearchJobsFilterPanel,
    },
    data() {
        return {
            showAdditionalFilters: false,
        }
    },
    computed: {
        allActiveFiltersCount() {
            return this.$store.getters['search/GetAllActiveFiltersCount']()
        },
    },
    methods: {
        resetFilters() {
            this.$store.commit('search/resetFilters')
        },
        toggleAdditionalFilters() {
            this.showAdditionalFilters = !this.showAdditionalFilters
        },
    },
}
