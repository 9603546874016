import { render, staticRenderFns } from "./SearchBar.vue?vue&type=template&id=dee9ca56&scoped=true&"
import script from "./SearchBar.vue?vue&type=script&lang=js&"
export * from "./SearchBar.vue?vue&type=script&lang=js&"
import style0 from "./SearchBar.vue?vue&type=style&index=0&id=dee9ca56&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dee9ca56",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchFilters: require('/build/6776f46d-236b-4b04-9823-6574f47d996f/front/components/search/SearchFilters.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCol,VIcon,VRow,VTextField})
