import { render, staticRenderFns } from "./AppBarDesktop.vue?vue&type=template&id=073b641b&scoped=true&"
import script from "./AppBarDesktop.vue?vue&type=script&lang=js&"
export * from "./AppBarDesktop.vue?vue&type=script&lang=js&"
import style0 from "./AppBarDesktop.vue?vue&type=style&index=0&id=073b641b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "073b641b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchBar: require('/build/6776f46d-236b-4b04-9823-6574f47d996f/front/components/search/SearchBar.vue').default,Avatar: require('/build/6776f46d-236b-4b04-9823-6574f47d996f/front/components/Avatar.vue').default,UserSettings: require('/build/6776f46d-236b-4b04-9823-6574f47d996f/front/components/UserSettings.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAppBar,VBtn,VIcon,VMenu,VSpacer,VToolbarTitle})
